import {initClient} from '@dudagroup/editor'
import {config} from './config/config'
import * as Sentry from '@sentry/react'
import './i18n'

import 'rsuite/styles/index.less'
import '@dudagroup/editor/src/client/global.less'
import '@dudagroup/editor/src/client/atoms/toolbar.less'
import '@dudagroup/editor/src/client/blocks/richTextBlock/toolbar/tableMenu.less'

const settings = document.getElementById('settings')
if (settings?.textContent) {
  const {sentryRelease} = JSON.parse(settings.textContent)
  if (sentryRelease) {
    Sentry.init({
      dsn: 'https://9ec1643206374f8cbf0a7a44304b3423@o49061.ingest.sentry.io/5830375',

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [new Sentry.Replay()],

      release: 'editor@' + sentryRelease,
    })
  }
}

if (document.readyState !== 'loading') {
  initClient(config)
} else {
  document.addEventListener('DOMContentLoaded', () => {
    initClient(config)
  })
}
