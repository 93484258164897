import React, {useState} from 'react'
import {BlockProps} from '../atoms/blockList'
import {AccordionBlockValue, AccordionBlockValueItem} from './types'
import {IconButton, Panel, FlexboxGrid, List} from 'rsuite'
import {AccordionEdit} from './accordionEdit'
import {getEmptyI18nValue} from '../utility'
import {nanoid} from 'nanoid'
import {ContentEditActionEnum} from '@dudagroup/editor/lib/client/control/contentReducer'
import {useTranslation} from 'react-i18next'

import PlusCircle from '@rsuite/icons/legacy/PlusCircle'
import Pencil from '@rsuite/icons/legacy/Pencil'
import LongArrowUp from '@rsuite/icons/legacy/LongArrowUp'
import LongArrowDown from '@rsuite/icons/legacy/LongArrowDown'
import MinusSquare from '@rsuite/icons/legacy/MinusSquare'

export type AccordionBlockProps = BlockProps<AccordionBlockValue>

export function AccordionBlock(props: AccordionBlockProps) {
  const {value, onChange, configs, languageLaneL, dispatch, path} = props
  const {accordion} = value
  const [currentPanelId, setCurrentPanelId] = useState<string | null>(null)
  const [currentPanelIndex, setCurrentPanelIndex] = useState<number>(-1)
  const {t} = useTranslation()

  const accordionPanels = accordion.map((_item, i, array) => {
    const {content: item} = _item

    return (
      <List.Item key={i} index={i}>
        <Panel
          key={item.id}
          bodyProps={{style: {padding: '0px'}}}
          header={
            <div className="show-grid">
              <FlexboxGrid>
                <FlexboxGrid.Item style={{marginTop: '0px', fontSize: '1rem'}} colspan={12}>
                  {item.title[languageLaneL] || '...'}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={12}
                  style={{
                    textAlign: 'right',
                    paddingRight: '20px',
                    marginTop: '-7px',
                  }}>
                  {i > 0 && (
                    <IconButton
                      icon={<LongArrowUp />}
                      appearance="subtle"
                      size="md"
                      onClick={(e) => {
                        e.stopPropagation()

                        dispatch({
                          type: ContentEditActionEnum.splice,
                          path: [...path, 'accordion'],
                          start: i - 1,
                          delete: 2,
                          insert: [_item, array[i - 1]],
                        })
                      }}
                      disabled={i === 0}
                    />
                  )}

                  {i < array.length - 1 && (
                    <IconButton
                      icon={<LongArrowDown />}
                      appearance="subtle"
                      size="md"
                      onClick={(e) => {
                        e.stopPropagation()

                        dispatch({
                          type: ContentEditActionEnum.splice,
                          path: [...path, 'accordion'],
                          start: i,
                          delete: 2,
                          insert: [array[i + 1], _item],
                        })
                      }}
                      disabled={i === array.length - 1}
                    />
                  )}

                  <IconButton
                    icon={<Pencil />}
                    appearance="subtle"
                    size="md"
                    onClick={() => {
                      setCurrentPanelIndex(i)
                      setCurrentPanelId(item.id)
                    }}
                  />

                  <IconButton
                    icon={<MinusSquare />}
                    appearance="subtle"
                    size="md"
                    onClick={() => {
                      dispatch({
                        type: ContentEditActionEnum.splice,
                        path: [...path, 'accordion'],
                        start: i,
                        delete: 1,
                      })
                    }}
                  />
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>
          }></Panel>
      </List.Item>
    )
  })

  return (
    <>
      <IconButton
        placement="left"
        appearance="ghost"
        icon={<PlusCircle />}
        onClick={() => {
          const accordionItem = {
            content: {
              id: nanoid(),
              title: getEmptyI18nValue(configs.apiConfig.languages, t('blockList.newPanel')),
              content: [],
            } as AccordionBlockValueItem,
          }
          onChange([...accordion, accordionItem], ['accordion'])
        }}>
        {t('blockList.addAccordionGroup')}
      </IconButton>
      <br />
      <br />
      {accordionPanels.length > 0 && (
        <List
          sortable
          pressDelay={300}
          onSort={(payload) => {
            if (payload) {
              dispatch({
                type: ContentEditActionEnum.swap,
                path: [...path, 'accordion'],
                newIndex: payload.newIndex,
                oldIndex: payload.oldIndex,
              })
            }
          }}>
          {accordionPanels}
        </List>
      )}

      <AccordionEdit
        {...props}
        currentPanelId={currentPanelId}
        onClose={(val) => {
          onChange(val, ['accordion', currentPanelIndex, 'content', 'content'])
          setCurrentPanelId(null)
        }}></AccordionEdit>
    </>
  )
}
