import React, {useEffect, useState} from 'react'
import {BlockProps} from '../atoms/blockList'
import {AccordionBlockValue, TableBlockValue, TableBlockValueRow} from './types'
import {
  IconButton,
  Panel,
  PanelGroup,
  ButtonToolbar,
  Placeholder,
  InputNumber,
  Form,
  Toggle,
  InputGroup,
} from 'rsuite'
import {getEditorLabel, getEmptyRichText} from '../utility'
import {nanoid} from 'nanoid'
import {ContentEditActionEnum} from '@dudagroup/editor/lib/client/control/contentReducer'
import {useTranslation} from 'react-i18next'

import PlusCircle from '@rsuite/icons/legacy/PlusCircle'
import Pencil from '@rsuite/icons/legacy/Pencil'
import LongArrowUp from '@rsuite/icons/legacy/LongArrowUp'
import LongArrowDown from '@rsuite/icons/legacy/LongArrowDown'
import MinusSquare from '@rsuite/icons/legacy/MinusSquare'
import {TableRowEdit} from './tableRowEdit'
import {TableHeaderEdit} from './tableHeaderEdit'
import {RichTextBlock} from '@dudagroup/editor'

export type AccordionBlockProps = BlockProps<AccordionBlockValue>

export function TableBlock(props: BlockProps<TableBlockValue>) {
  const {model, value, onChange, configs, languageLaneL, dispatch, path, autofocus} = props
  const {columnCount, rows, header, cellWidth, disableMargin} = value
  const [currentPanelId, setCurrentPanelId] = useState<string | null>(null)
  const [currentPanelIndex, setCurrentPanelIndex] = useState<number>(-1)

  const [columnsNumber, setColumnsNumber] = React.useState(columnCount || 0)
  // const [headerTrash, setHeaderTrash] = useState<any>()

  const maxColumnCount = 10

  const handleMinus = () => {
    if (columnsNumber < 1) return

    const minus = parseInt(columnsNumber as unknown as string) - 1

    setColumnsNumber(minus)

    // TODO: do all of this before save
    rows.map((item) => {
      item.content.content = item.content.content.splice(0, minus)
    })
    header.content = header.content.splice(0, minus)

    cellWidth.content = cellWidth.content.splice(0, minus)

    onChange(minus, ['columnCount'])
  }
  const handlePlus = () => {
    if (columnsNumber === maxColumnCount) return

    const plus = parseInt(columnsNumber as unknown as string) + 1

    setColumnsNumber(plus)

    rows.map((item) => {
      item.content.content.push({
        id: nanoid(),
        content: createEmptyRichText(),
      })
    })

    // const headerContent = createEmptyRichText()
    // if (headerTrash[columnsNumber]) {
    //   console.log(headerTrash[columnsNumber])
    //   headerContent.richText.text = headerTrash[columnsNumber].content.richText.text
    // }
    header.content.push({
      id: nanoid(),
      content: createEmptyRichText(),
    })

    cellWidth.content.push({
      content: {
        id: nanoid(),
        width: 1,
      },
    })

    onChange(plus, ['columnCount'])
  }

  const {t} = useTranslation()

  function createEmptyRichText() {
    return {
      richText: {
        text: {
          de: [{type: 'paragraph', children: [{text: ''}]}],
          fr: [{type: 'paragraph', children: [{text: ''}]}],
          en: [{type: 'paragraph', children: [{text: ''}]}],
          it: [{type: 'paragraph', children: [{text: ''}]}],
        },
      },
    }
  }

  const Max32BitIntSize = Math.pow(2, 31) - 1

  const tableRowsPanels = rows.map((_item, i, array) => {
    const {content: item} = _item

    const text = item.content[0]?.content.richText.text[languageLaneL][0].children[0].text || ''

    const header = text?.trim()
      ? `${i + 1}. ${text.slice(0, 30)}${text.length > 30 ? '...' : ''}`
      : `${i + 1}. ${t('blocks.table.row')}`

    return (
      <Panel key={item.id} header={header}>
        <>
          <ButtonToolbar>
            <IconButton
              icon={<Pencil />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                setCurrentPanelIndex(i)
                setCurrentPanelId(item.id)
              }}>
              {t('blockList.edit')}
            </IconButton>
            <IconButton
              icon={<LongArrowUp />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                dispatch({
                  type: ContentEditActionEnum.splice,
                  path: [...path, 'rows'],
                  start: i - 1,
                  delete: 2,
                  insert: [_item, array[i - 1]],
                })
              }}>
              {t('blockList.moveUp')}
            </IconButton>
            <IconButton
              icon={<LongArrowDown />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                dispatch({
                  type: ContentEditActionEnum.splice,
                  path: [...path, 'rows'],
                  start: i,
                  delete: 2,
                  insert: [array[i + 1], _item],
                })
              }}>
              {t('blockList.moveDown')}
            </IconButton>
            <IconButton
              icon={<MinusSquare />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                dispatch({
                  type: ContentEditActionEnum.splice,
                  path: [...path, 'rows'],
                  start: i,
                  delete: 1,
                })
              }}>
              {t('blockList.delete')}
            </IconButton>
          </ButtonToolbar>

          <Placeholder.Paragraph />
        </>
      </Panel>
    )
  })

  const tableHeaderPanel = (
    <IconButton
      icon={<Pencil />}
      appearance={'ghost'}
      placement="left"
      onClick={() => {
        setCurrentPanelIndex(1)
        setCurrentPanelId(nanoid())
      }}>
      {'Header'} {t('blockList.edit')}
    </IconButton>
  )

  const cellWidthR = cellWidth.content.map((item: any, index: number) => {
    return (
      <InputNumber
        style={{width: 80, marginRight: 10}}
        key={item.content.id}
        value={item.content.width}
        step={1}
        max={4}
        min={1}
        onChange={(val) => {
          const value = Math.min(Math.max(Number(val), -Max32BitIntSize), Max32BitIntSize)

          onChange(value, ['cellWidth', 'content', index, 'content', 'width'])
        }}
      />
    )
  })

  const addRow = (position: 'above' | 'below') => {
    const content = []

    let count = 0

    while (count < columnsNumber) {
      content.push({
        id: nanoid(),
        content: createEmptyRichText(),
      })
      count++
    }

    const rowItem = {
      content: {
        id: nanoid(),
        content: content,
      } as TableBlockValueRow,
    }

    if (position === 'above') {
      onChange([rowItem, ...rows], ['rows'])
    } else {
      onChange([...rows, rowItem], ['rows'])
    }
  }

  return (
    <>
      <div className="wep-label" style={{marginTop: 30, height: '24px'}}>
        <Form.ControlLabel style={{display: 'inline-block'}}>
          {t('blocks.table.columns')}
        </Form.ControlLabel>
      </div>

      <InputGroup style={{maxWidth: '130px'}}>
        <InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
        <div style={{margin: '5px 22px', width: '100%', textAlign: 'center'}}>{columnsNumber}</div>
        <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
      </InputGroup>

      <br />

      {cellWidth.content.length > 0 && (
        <Form.ControlLabel style={{display: 'inline-block'}}>
          {t('blocks.table.columnWidth')}
        </Form.ControlLabel>
      )}
      <div style={{display: 'flex'}}>{cellWidthR}</div>

      <div className="wep-label" style={{marginTop: 30, height: '24px'}}>
        <Form.ControlLabel style={{display: 'inline-block'}}>
          {t('blocks.table.disableMargin')}
        </Form.ControlLabel>
      </div>
      <Toggle
        checkedChildren={t('global.buttons.yes')}
        unCheckedChildren={t('global.buttons.no')}
        checked={disableMargin}
        onChange={() => onChange(!disableMargin, ['disableMargin'])}
      />

      <div className="wep-label" style={{marginTop: 30, height: '24px'}}>
        <Form.ControlLabel style={{display: 'inline-block'}}>
          {t('blocks.table.activeHeader')}
        </Form.ControlLabel>
      </div>

      <Toggle
        style={{marginTop: 4, marginBottom: 4, fontSize: 15}}
        checkedChildren={t('global.buttons.yes')}
        unCheckedChildren={t('global.buttons.no')}
        checked={header.active}
        onChange={() => {
          onChange({...header, active: !header.active}, ['header'])
        }}
      />

      {header.active && (
        <>
          <br />
          <br />
          <PanelGroup accordion>{tableHeaderPanel}</PanelGroup>

          <TableHeaderEdit
            {...props}
            currentPanelId={currentPanelId}
            onClose={(val) => {
              onChange(val, ['header', 'content', 'content'])
              setCurrentPanelId(null)
            }}
          />
        </>
      )}

      <br />
      <br />
      <br />

      <IconButton
        style={{marginRight: 10}}
        placement="left"
        appearance="ghost"
        icon={<PlusCircle />}
        onClick={() => addRow('above')}>
        {t('blocks.table.addRowAbove')}
      </IconButton>

      <IconButton
        placement="left"
        appearance="ghost"
        icon={<PlusCircle />}
        onClick={() => addRow('below')}>
        {t('blocks.table.addRowBelow')}
      </IconButton>

      <br />
      <br />

      {tableRowsPanels.length > 0 ? (
        <PanelGroup accordion bordered>
          {tableRowsPanels}
        </PanelGroup>
      ) : null}

      <TableRowEdit
        {...props}
        currentPanelId={currentPanelId}
        onClose={(val) => {
          onChange(val, ['rows', currentPanelIndex, 'content', 'content'])
          setCurrentPanelId(null)
        }}
      />
    </>
  )
}
