export function getStaticSlug(path: string, lang: string) {
  function p(de: string, fr: string, it: string, en: string) {
    switch (lang) {
      case 'fr':
        return fr
      case 'it':
        return it
      case 'en':
        return en
      default:
        return de
    }
  }

  /** if this is modified it must also be modified under:
   * https://snsf-ch.atlassian.net/wiki/spaces/RESNF/pages/1524892022/Mapping+Konzept
   * - packages/website/lib/api.ts -> getStaticSlug
   * - packages/api/src/customApi/customApiPublic.ts -> getStaticSlug
   * - packages/api/src/utils/forwardUtils.ts
   * - packages/editor/src/client/config/common/StaticSlug.ts
   */
  switch (path) {
    case 'page':
      return p('seite', 'page', 'pagina', 'page')
    case 'news':
      return p('news', 'news', 'news', 'news')
    case 'event':
      return p('event', 'evenement', 'evento', 'event')
    case 'multimedia':
      return p('multimedia', 'mediatheque', 'mediacentre', 'mediacentre')
    case 'funding':
      return p('foerderung', 'encouragement', 'sostegno', 'funding')
    case 'impulseTopic':
      return p('thema', 'dossier', 'tema', 'topic')
    default:
      return ''
  }
}
