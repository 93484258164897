import {EditorConfig, ExtensionType} from '@dudagroup/editor'
import React from 'react'
import {CustomViewCopyData, CustomViewImporter, CustomViewReindex} from './customViews/customView'
import CustomViewExportEmployees from './customViews/customViewExportEmployees'
import CustomViewFilesUpload from './customViews/customViewFilesUpload'
import {getNavigationView} from './models/navigation/navigationView'
import {getGenericViewWithCustomBlockList, getPreviewLink} from './models/page/pageContentView'
import {CustomViewPurgecache} from './customViews/customViewPurgecache'

import Tag from '@rsuite/icons/legacy/Tag'
import ObjectUngroup from '@rsuite/icons/legacy/ObjectUngroup'
import Globe from '@rsuite/icons/legacy/Globe'
import Home from '@rsuite/icons/legacy/Home'
import Sitemap from '@rsuite/icons/legacy/Sitemap'
import AngleDoubleDown from '@rsuite/icons/legacy/AngleDoubleDown'
import FileImageO from '@rsuite/icons/legacy/FileImageO'
import FileTextO from '@rsuite/icons/legacy/FileTextO'
import Project from '@rsuite/icons/legacy/Project'
import IconImport from '@rsuite/icons/legacy/Import'
import NewspaperO from '@rsuite/icons/legacy/NewspaperO'
import Calendar from '@rsuite/icons/legacy/Calendar'
import Money from '@rsuite/icons/legacy/Money'
import Question2 from '@rsuite/icons/legacy/Question2'
import Film from '@rsuite/icons/legacy/Film'
import BlackTie from '@rsuite/icons/legacy/BlackTie'
import PeopleGroup from '@rsuite/icons/legacy/PeopleGroup'
import FileDownload from '@rsuite/icons/legacy/FileDownload'
import Building from '@rsuite/icons/legacy/Building'
import AddressBookO from '@rsuite/icons/legacy/AddressBookO'
import PreviewLinkCopy from './PreviewLinkCopy'

export const MODEL_MEDIA = 'media'
export const LANGUAGES = ['de', 'fr', 'en', 'it']

export const DEFAULT_LANGUAGE = (() => {
  const settings = document.getElementById('settings')
  if (settings?.textContent) {
    const {defaultLanguage} = JSON.parse(settings.textContent)
    return defaultLanguage as string
  }
  return 'de'
})()

export const config: EditorConfig = {
  navigationBar: {
    articlesActive: false,
    authorsActive: false,
    imageLibraryActive: false,
    commentsActive: false,
    navigationActive: false,
    pagesActive: false,
  },
  extensions: [
    {
      identifier: 'nationalResearchProgramme',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'NFP',
        en: 'NFP',
      },
      namePlural: {
        de: 'Nationale Forschungsprogramme',
        en: 'National Research Programmes',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'domain',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Domain',
      namePlural: 'Domains',
      icon: <Globe />,
    },
    {
      identifier: 'website',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Website',
      namePlural: 'Websites',
      icon: <Home />,
    },
    {
      identifier: 'navigation',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Navigation',
      namePlural: 'Navigations',
      icon: <Sitemap />,
      getContentView: getNavigationView,
    },
    {
      identifier: 'footer',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Footer',
      namePlural: 'Footers',
      icon: <AngleDoubleDown />,
    },
    {
      identifier: 'tag',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Tag',
      namePlural: 'Tags',
      icon: <Tag />,
    },
    {
      identifier: MODEL_MEDIA,
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Media',
      namePlural: 'Media',

      customColumns: [
        {
          headerTranslationKey: 'Vorschau',
          previewPaths: [['content', 'media']],
          useAsPreviewInDetailView: true,
        },
        {
          headerTranslationKey: 'Preview Links',
          fetchEnrichedContent: true,
          getCustomViewContent: (content, contentDefaultLang) => {
            return (
              <PreviewLinkCopy content={content} isMedia={true} defaultLang={contentDefaultLang} />
            )
          },
        },
      ],
      previewSize: 'big',
      icon: <FileImageO />,
      getPreviewLink: (token, language, recordData) => {
        const settings = document.getElementById('settings')
        const media = recordData?.content?.media?.[language]?.media
        if (settings?.textContent && media?.filename && media?.extension) {
          const {mediaPreviewUrl} = JSON.parse(settings.textContent)

          if (
            (recordData.meta.robotsMetaDirectives === 'indexFollow' ||
              recordData.meta.robotsMetaDirectives === 'indexNoFollow' ||
              !recordData.meta.robotsMetaDirectives) &&
            recordData.content.media[language].media.image
          ) {
            return `${mediaPreviewUrl}/media/${language}/${recordData.id}/${media.filename}${media.extension}`
          }

          return `${mediaPreviewUrl}/media-noindex/${language}/${recordData.id}/${media.filename}${media.extension}`
        }

        return null
      },
      disableDuplication: true,
    },
    {
      identifier: 'fileuploader',
      type: ExtensionType.CusomExtension,
      nameSingular: {
        de: 'Medien hochladen',
        en: 'Upload media',
      },
      namePlural: {
        de: 'Medien hochladen',
        en: 'Upload media',
      },
      icon: <FileImageO />,
      view: <CustomViewFilesUpload />,
    },
    {
      identifier: 'impulseTopic',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Impulse Thema',
        en: 'Impulse Topic',
      },
      namePlural: {
        de: 'Impulsthemen',
        en: 'Impulse Topics',
      },
      icon: <FileTextO />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',
          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'page',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Seite',
        en: 'Page',
      },
      namePlural: {
        de: 'Seiten',
        en: 'Pages',
      },
      icon: <FileTextO />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',
          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'news',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'News',
      namePlural: 'News',
      icon: <NewspaperO />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',
          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'categoryNews',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'News Kategorie',
        en: 'News Category',
      },
      namePlural: {
        de: 'News Kategorien',
        en: 'News Categories',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'event',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Event',
      namePlural: 'Events',
      icon: <Calendar />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',
          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'eventType',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Event Typ',
        en: 'Event Type',
      },
      namePlural: {
        de: 'Eventtypen',
        en: 'Event types',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'funding',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Förderinstrument',
        en: 'Funding',
      },
      namePlural: {
        de: 'Förderinstrumente',
        en: 'Fundings',
      },
      icon: <Money />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',
          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'categoryFunding',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Förderinstrument Kategorie',
        en: 'Funding Category',
      },
      namePlural: {
        de: 'Förderinstrument Kategorien',
        en: 'Funding Categories',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'faq',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Faq',
      namePlural: "Faq's",
      icon: <Question2 />,
    },
    {
      identifier: 'multimedia',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Multimedia',
      namePlural: 'Multimedia',
      icon: <Film />,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',
          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'categoryMultimedia',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Multimedia Kategorie',
        en: 'Multimedia Category',
      },
      namePlural: {
        de: 'Multimedia Kategorien',
        en: 'Multimedia Categories',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'panelMember',
      type: ExtensionType.ContentModelExtension,
      namePlural: 'Panel Members',
      nameSingular: 'Panel Member',
      hidden: false,
      icon: <BlackTie />,
    },
    {
      identifier: 'panelMemberFunction',
      type: ExtensionType.ContentModelExtension,
      namePlural: 'Panel Member Functions',
      nameSingular: 'Panel Member Function',
      hidden: false,
      icon: <Project />,
    },
    {
      identifier: 'panelMemberEvaluation',
      type: ExtensionType.ContentModelExtension,
      namePlural: 'Panel Member Evaluations',
      nameSingular: 'Panel Member Evaluation',
      hidden: false,
      icon: <Project />,
    },
    {
      identifier: 'panelMemberImport',
      type: ExtensionType.ContentModelExtension,
      namePlural: 'Panel Member Imports',
      nameSingular: 'Panel Member Import',
      hidden: false,
      icon: <Project />,
    },
    {
      identifier: 'importer',
      type: ExtensionType.CusomExtension,
      nameSingular: 'Panel Member Auto Importer',
      namePlural: 'Panel Member Auto Importer',
      view: <CustomViewImporter />,
      icon: <IconImport />,
    },
    {
      identifier: 'employee',
      type: ExtensionType.ContentModelExtension,
      customColumns: [
        {
          headerTranslationKey: 'Vorschau',
          previewPaths: [['content', 'image']],
          useAsPreviewInDetailView: true,
        },
      ],
      previewSize: 'big',
      nameSingular: {
        de: 'Mitarbeiter',
        en: 'Employee',
      },
      namePlural: {
        de: 'Mitarbeiter',
        en: 'Employees',
      },
      icon: <PeopleGroup />,
    },
    {
      identifier: 'employeeCsvExport',
      type: ExtensionType.CusomExtension,
      nameSingular: {
        de: 'Mitarbeiter CSV Export',
        en: 'Employee CSV Export',
      },
      namePlural: {
        de: 'Mitarbeiter CSV Export',
        en: 'Employees CSV Export',
      },
      icon: <FileDownload />,
      view: <CustomViewExportEmployees />,
    },
    {
      identifier: 'division',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Abteilung',
        en: 'Division',
      },
      namePlural: {
        de: 'Abteilungen',
        en: 'Divisions',
      },
      icon: <Building />,
    },
    {
      identifier: 'organizationUnit',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Org. Einheit',
        en: 'Org. unit',
      },
      namePlural: {
        de: 'Org. Einheiten',
        en: 'Org. units',
      },
      icon: <Building />,
    },
    {
      identifier: 'contact',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Kontakt',
        en: 'Contact',
      },
      namePlural: {
        de: 'Kontakte',
        en: 'Contacts',
      },
      icon: <AddressBookO />,
    },
    {
      identifier: 'reindex',
      type: ExtensionType.CusomExtension,
      nameSingular: 'Reindex',
      namePlural: 'Reindex',
      view: <CustomViewReindex />,
    },
    {
      identifier: 'cache',
      type: ExtensionType.CusomExtension,
      nameSingular: 'Cache',
      namePlural: 'Cache',
      view: <CustomViewPurgecache />,
    },
    {
      identifier: 'copyData',
      type: ExtensionType.CusomExtension,
      nameSingular: {
        de: 'Daten kopieren',
        en: 'Copy Data',
      },
      namePlural: {
        de: 'Daten kopieren',
        en: 'Copy Data',
      },
      view: <CustomViewCopyData />,
    },
  ],
}
