import {BlockObject, Configs} from '@dudagroup/editor'
import LanguageControl from '@dudagroup/editor/lib/client/atoms/contentEdit/LanguageControl'
import {
  ContentEditAction,
  ContentEditActionEnum,
  contentReducer,
} from '@dudagroup/editor/lib/client/control/contentReducer'
import {ContentModelSchema} from '@dudagroup/editor/lib/client/interfaces/contentModelSchema'
import React, {useEffect, useReducer, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Drawer, Grid, Panel} from 'rsuite'
import {
  CurrentNode,
  transformFromRsuiteToWePublishPath,
  transformFromRsuiteToWePublishSchema,
} from './navigationView'

export function NavigationDrawer(props: {
  langLaneL: string
  langLaneR: string
  langUi: string
  currentNode: any
  configs: Configs
  contentModelSchema: ContentModelSchema
  dispatch: React.Dispatch<ContentEditAction>
  setCurrentNode: (prop: CurrentNode | undefined) => void
  deleteCurrentNode: (prop: CurrentNode) => void
}) {
  const {
    langLaneL,
    langLaneR,
    langUi,
    configs,
    dispatch,
    currentNode,
    setCurrentNode,
    deleteCurrentNode,
    contentModelSchema,
  } = props
  const [langLane1, setLangLane1] = useState(langLaneL)
  const [langLane2, setLangLane2] = useState(langLaneR)
  const {t} = useTranslation()

  const [{record}, dispatcher] = useReducer(contentReducer, {
    record: {blocks: currentNode.node},
    hasChanged: false,
  })

  useEffect(() => {
    dispatcher({
      type: ContentEditActionEnum.setInitialState,
      value: currentNode.node,
    })
  }, [currentNode.node])

  const fields = (contentModelSchema.content as any).navigation.contentType.fields
  const model = {
    inactive: fields.inactive,
    labelI18n: fields.labelI18n,
    link: fields.link,
    parentForContentOfType: fields.parentForContentOfType,
    parentForTag: fields.parentForTag,
    childContents: fields.childContents,
  }

  const header = (
    <LanguageControl
      languagesConfig={configs.apiConfig.languages}
      langLaneL={langLane1}
      langLaneR={langLane2}
      setLangLaneL={setLangLane1}
      setLangLaneR={setLangLane2}
    />
  )

  return (
    <Drawer
      open={!!currentNode}
      placement={'bottom'}
      size="full"
      onClose={() => {
        setCurrentNode(undefined)
      }}>
      <Drawer.Header>
        <Drawer.Title>{t('treeNavigation.navigationNode')}</Drawer.Title>

        <Drawer.Actions>
          <Button
            appearance="primary"
            color="green"
            onClick={() => {
              if (currentNode?.path) {
                const path = transformFromRsuiteToWePublishPath([...currentNode.path])
                const value = transformFromRsuiteToWePublishSchema(langLane1, [record])[0]
                dispatch({
                  type: ContentEditActionEnum.update,
                  path: path,
                  value: value,
                })
                setCurrentNode(undefined)
              }
            }}>
            {t('treeNavigation.apply')}
          </Button>
          <Button
            appearance="ghost"
            color="red"
            onClick={() => {
              deleteCurrentNode(currentNode)
            }}>
            {t('treeNavigation.deleteNode')}
          </Button>
          <Button
            appearance="subtle"
            onClick={() => {
              setCurrentNode(undefined)
            }}>
            {t('treeNavigation.close')}
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body
      // onClose={() => {
      //   setCurrentNode(undefined)
      // }}
      >
        <Grid>
          <div className="wep-editor-langcontrol">{header}</div>
          <Panel bordered>
            <BlockObject
              model={{
                type: 'object' as any,
                fields: model,
              }}
              value={record}
              schemaPath={[]}
              configs={configs}
              dispatch={dispatcher}
              languageContext={{
                langLane1: langLane1,
                langLane2: langLane2,
                languagesConfig: configs.apiConfig.languages,
                langUi: langUi,
              }}></BlockObject>
          </Panel>
        </Grid>
      </Drawer.Body>
    </Drawer>
  )
}
